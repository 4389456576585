import React from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import Button from "../components/Button";

function Connect() {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        mounted,
      }) => {
        const ready = mounted;
        const connected = ready && account && chain;
        if (!connected) {
          return (
            <Button onClick={openConnectModal} smol={true}>
              connect&nbsp;wallet
            </Button>
          );
        }
        if (chain.unsupported) {
          return (
            <Button onClick={openChainModal} danger={true}>
              wrong&nbsp;network
            </Button>
          );
        }
        return (
          <Button onClick={openAccountModal} smol={true}>
            {account.displayName}
          </Button>
        );
      }}
    </ConnectButton.Custom>
  );
}

export default Connect;
