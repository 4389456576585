import React from "react";
import "./Button.css";

function Button({
  children,
  onClick,
  danger,
  smol,
}: {
  children: string;
  onClick?: () => void;
  danger?: boolean;
  smol?: boolean;
}) {
  return (
    <div
      className={`button-container ${danger ? "button-danger" : ""}`}
      style={{ margin: smol ? "0 1rem" : undefined }}
    >
      <div className="button-shadow" />
      <button
        className="button-main"
        style={{ padding: smol ? "0 1rem" : undefined }}
        type="button"
        onClick={onClick}
      >
        {smol ? <h3>{children}</h3> : <h1>{children}</h1>}
      </button>
    </div>
  );
}

export default Button;
