import React, { Fragment, useState } from "react";
import Button from "../components/Button";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import abi from "../util/abi";
import { BigNumber } from "ethers";
import Connect from "../common/Connect";
import Pending from "../common/Pending";
import Counter from "../components/Counter";
import useWindowDimensions from "../util/useWindowDimensions";
import hero from "../assets/hero.gif";

const GOERLI_CONTRACT_ADDRESS = "0xc737dFdd4FAf16Be3aE74E28CEa3d24cb75e5a75";
const MAINNET_CONTRACT_ADDRESS = "0x97564Aae9Aa2f8ffCD51A22472192F8d475abB19";

function MintAction() {
  const { width } = useWindowDimensions();

  const isNarrow = width < 1000;

  const [count, setCount] = useState(1);
  const { isConnected, isConnecting } = useAccount();

  const network = useNetwork();

  const CONTRACT_ADDRESS =
    network?.chain?.name === "Ethereum"
      ? MAINNET_CONTRACT_ADDRESS
      : GOERLI_CONTRACT_ADDRESS;

  const priceData = useContractRead({
    abi,
    address: CONTRACT_ADDRESS,
    functionName: "price",
  });

  const price = (priceData.data as BigNumber) ?? BigNumber.from(0);

  const { config } = usePrepareContractWrite({
    address: CONTRACT_ADDRESS,
    abi,
    functionName: "mint",
    overrides: {
      value: price.mul(count),
      gasLimit: BigNumber.from(350000).mul(count),
    },
  });

  const { data, isLoading, isSuccess, isError, error, write } =
    useContractWrite(config);

  const waitData = useWaitForTransaction({
    hash: data?.hash,
  });

  if (isLoading || isSuccess || waitData.isLoading || waitData.isSuccess) {
    return <Pending isLoading={!waitData.isSuccess} approved={isSuccess} />;
  }

  if (!isConnected) {
    if (isConnecting) {
      return <h1>connecting ...</h1>;
    } else {
      return <Connect />;
    }
  }

  return (
    <div>
      {isError && (
        <h1 style={{ color: "#fc3400", textAlign: "center" }}>
          {error?.message}
        </h1>
      )}
      <div
        style={{
          width: "fit-content",
          margin: "auto",
          display: isNarrow ? undefined : "inline-block",
        }}
      >
        <Counter n={count} setN={setCount} max={100} />
      </div>
      <div
        style={{
          display: isNarrow ? undefined : "inline-block",
          width: "fit-content",
          margin: "auto",
        }}
      >
        <Button onClick={() => write && write()}>mint</Button>
      </div>
    </div>
  );
}

function Mint() {
  return (
    <Fragment>
      <h1 style={{ fontSize: 100, width: "100%", textAlign: "center" }}>
        SHORTCUTS
      </h1>
      <h3 style={{ width: "100%", textAlign: "center", marginTop: "-5rem" }}>
        A 3 panel open edition from 3 Panel Crimes and CADA
      </h3>
      <div style={{ width: "100%", display: "flex" }}>
        <img
          style={{ margin: "auto", height: "55vh" }}
          src={hero}
          alt="A sample of the art."
        />
      </div>
      <div style={{ width: "100%", marginTop: "2rem" }}>
        <div style={{ margin: "auto", width: "fit-content" }}>
          <MintAction />
        </div>
        <p style={{ textAlign: "center", width: "100%" }}>
          Each pack contains 1 of each edition (3 in total!) at .03 ETH each!
        </p>
      </div>
    </Fragment>
  );
}

export default Mint;
