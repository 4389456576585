import React, { Fragment, useEffect, useState } from "react";
import Button from "../components/Button";
import useWindowDimensions from "../util/useWindowDimensions";
import Window from "../components/Window";

import fifi from "../assets/fifi.png";
import chad from "../assets/chad.png";
import car1 from "../assets/car1.png";
import car2 from "../assets/car2.png";
import car3 from "../assets/car3.png";
import car4 from "../assets/car4.png";
import car5 from "../assets/car5.png";
import c1 from "../assets/c1.png";
import c2 from "../assets/c2.png";
import c3 from "../assets/c3.png";
import c4 from "../assets/c4.png";
import c5 from "../assets/c5.png";
import c6 from "../assets/c6.png";
import ts2 from "../assets/ts2.png";
import sl2 from "../assets/sl2.png";
import ig from "../assets/ig.png";

function getWindowOffsets(height: number, width: number) {
  const topOffset = 0.1 + 0.8 * (Math.random() * height);
  const leftOffset = 0.1 + 0.8 * (Math.random() * width);

  return { topOffset, leftOffset };
}

function TransactionApproved({
  height,
  width,
  click,
}: {
  height: number;
  width: number;
  click: () => void;
}) {
  return (
    <Window
      key="load"
      leftOffset={width / 2 - 400}
      topOffset={height / 2 - 150}
      title="Mint Submitted"
    >
      <h1 style={{ padding: "30px" }}>
        Blockchains take a minute, please stand by...
      </h1>

      <div style={{ margin: "auto", width: "fit-content" }}>
        <Button onClick={click}>
          don't click this. it's just a placeholder.
        </Button>
      </div>
    </Window>
  );
}

function InitialLoad({ height, width }: { height: number; width: number }) {
  return (
    <Window
      key="load"
      leftOffset={width / 2 - 300}
      topOffset={height / 2 - 300}
      title="MINT?"
    >
      <div style={{ maxWidth: "400px" }}>
        <h1 style={{ padding: "30px" }}>Mint has begun</h1>
        <p style={{ padding: "30px" }}>
          If you're seeing this message, you probably have a transaction to
          approve. So stop reading and go approve the mint transaction.
          Seriously. Stop procrastinating, it isn't doing you any good
        </p>
      </div>
    </Window>
  );
}

function Final({ height, width }: { height: number; width: number }) {
  const dim = useWindowDimensions();

  const isNarrow = dim.width < 1200;

  return (
    <Window
      leftOffset={width / 2 - 500}
      topOffset={height / 2 - 100}
      title="SUCCESS!"
    >
      <h1 style={{ fontSize: isNarrow ? 40 : 100, textAlign: "center" }}>
        TRANSACTION FINALIZED
      </h1>
    </Window>
  );
}

function Pending({
  isLoading,
  approved,
}: {
  isLoading: boolean;
  approved: boolean;
}) {
  const { height, width } = useWindowDimensions();
  const isNarrow = width < 1200;

  const [windows, setWindows] = useState<JSX.Element[]>([]);

  const addWindow = () => {
    const w = [...windows];

    const { leftOffset, topOffset } = getWindowOffsets(height, width);

    const r = Math.floor(Math.random() * 15);

    if (r === 0 || r === 1 || r === 2) {
      const name = ["PEPINA", "FIFI", "PEPA", "LADY", "CUTIE"];
      const buttonText = ["meet today!", "wtf...", "no thanks."];
      w.push(
        <Window
          leftOffset={leftOffset - 100}
          topOffset={topOffset - 200}
          title="HOT PEPES IN YOUR AREA"
          key={(windows.length + 1).toString()}
        >
          <div
            style={{
              display: "flex",
              flexDirection: isNarrow ? "column" : "row",
            }}
          >
            <img src={fifi} alt="pepe the frog dressed as a lady" />
            <div style={{ marginLeft: "30px" }}>
              <h1 style={{ fontSize: 30 }}>
                "{name[Math.floor(Math.random() * name.length)]}"
              </h1>
              <h1>... is {Math.floor(Math.random() * 10) + 2} miles away!</h1>
              <div style={{ margin: "auto", width: "fit-content" }}>
                <Button>
                  {buttonText[Math.floor(Math.random() * buttonText.length)]}
                </Button>
              </div>
            </div>
          </div>
        </Window>
      );
    } else if (r === 3 || r === 4 || r === 5) {
      const buttonText = ["PLZ...", "dev do something", "OK, DADDY!"];
      w.push(
        <Window
          leftOffset={leftOffset - 100}
          topOffset={topOffset - 200}
          title="CHAD'S SECRET GROWTH FORMULA"
          key={(windows.length + 1).toString()}
        >
          <div
            style={{
              display: "flex",
              maxWidth: "600px",
              textAlign: "center",
              flexDirection: isNarrow ? "column" : "row",
            }}
          >
            <div style={{ margin: "30px" }}>
              <h1 style={{ fontSize: 50, marginBottom: 0, marginTop: 0 }}>
                ADD {Math.floor(Math.random() * 10) + 5} PERCENT
              </h1>
              <h1 style={{ marginTop: "5px" }}>TO YOUR BAG</h1>
              <div style={{ margin: "auto", width: "fit-content" }}>
                <Button>
                  {buttonText[Math.floor(Math.random() * buttonText.length)]}
                </Button>
              </div>
            </div>
            <img
              style={{ width: 250, height: 250 }}
              src={chad}
              alt="a muscular man, a chad if you will"
            />
          </div>
        </Window>
      );
    } else if (r === 6 || r === 7 || r === 8) {
      const buttonText = ["OH HECK YEAH", "nice", "SCORE!"];
      const car = [car1, car2, car3, car4, car5];

      w.push(
        <Window
          leftOffset={leftOffset - 100}
          topOffset={topOffset - 200}
          title="YOU WON!"
          key={(windows.length + 1).toString()}
        >
          <div
            style={{
              display: "flex",
              maxWidth: "600px",
              textAlign: "center",
              flexDirection: isNarrow ? "column" : "row",
            }}
          >
            <div style={{ margin: "30px" }}>
              <h1 style={{ fontSize: 50, marginBottom: 0, marginTop: 0 }}>
                YOU WON A NEW CAR!!!!!!!!
              </h1>
              <div style={{ margin: "auto", width: "fit-content" }}>
                <Button>
                  {buttonText[Math.floor(Math.random() * buttonText.length)]}
                </Button>
              </div>
            </div>
            <img
              style={{ width: 250, height: 250 }}
              src={car[Math.floor(Math.random() * car.length)]}
              alt="a car"
            />
          </div>
        </Window>
      );
    } else if (r === 9 || r === 10 || r === 11) {
      const title = ["WATCH NOW!", "NEW MOVIE", "OUT NOW!"];
      const movies = [ts2, sl2, ig];

      w.push(
        <Window
          leftOffset={leftOffset - 100}
          topOffset={topOffset - 200}
          title={title[Math.floor(Math.random() * title.length)]}
          key={(windows.length + 1).toString()}
        >
          <div
            style={{
              display: "flex",
              justifyItems: "center",
              alignContent: "center",
            }}
          >
            <img
              style={{ width: 532 / 2, height: 800 / 2 }}
              src={movies[Math.floor(Math.random() * movies.length)]}
              alt="featured movie"
            />
          </div>
        </Window>
      );
    } else {
      const title = "CHOOSE YOUR CHARACTER";
      const characters = [c1, c2, c3, c4, c5, c6];

      w.push(
        <Window
          leftOffset={leftOffset - 100}
          topOffset={topOffset - 200}
          title={title}
          key={(windows.length + 1).toString()}
        >
          <div
            style={{
              display: "flex",
              justifyItems: "center",
              alignContent: "center",
            }}
          >
            <img
              style={{ width: 350, height: 350 }}
              src={characters[Math.floor(Math.random() * characters.length)]}
              alt="featured movie"
            />
          </div>
        </Window>
      );
    }

    setWindows(w);
  };

  useEffect(() => {
    if (approved && isLoading) {
      const interval = setInterval(() => {
        addWindow();
      }, 2000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windows, approved, isLoading]);

  return (
    <Fragment>
      <InitialLoad height={height} width={width} />
      {approved && (
        <TransactionApproved height={height} width={width} click={addWindow} />
      )}
      {windows}
      {!isLoading && <Final height={height} width={width} />}
    </Fragment>
  );
}

export default Pending;
