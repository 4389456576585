import React from "react";
import Mint from "./routes/Mint";

function App() {
  return (
    <div
      style={{
        width: "90vw",
        minHeight: "90vh",
        margin: "5vh 5vw",
      }}
    >
      <Mint />
    </div>
  );
}

export default App;
