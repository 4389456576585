import React from "react";
import plus from "../assets/plus.svg";
import square from "../assets/square.svg";
import minus from "../assets/minus.svg";
import useWindowDimensions from "../util/useWindowDimensions";

function Window({
  children,
  topOffset,
  leftOffset,
  title,
}: {
  children: any;
  topOffset: number;
  leftOffset: number;
  title?: string;
}) {
  const { width } = useWindowDimensions();

  const isNarrow = width < 1200;

  return (
    <div
      style={{
        margin: "5vh 5vw",
        border: "6px solid #E48423",
        top: isNarrow ? `${topOffset / 2}px` : `${topOffset}px`,
        left: isNarrow ? "10px" : `${leftOffset}px`,
        width: isNarrow ? "calc(90vw - 20px)" : undefined,
        height: isNarrow ? "fit-content" : undefined,
        borderRadius: "none",
        position: "absolute",
        backgroundColor: "#f2e1bc",
      }}
    >
      <div style={{ padding: "0 10px", borderBottom: "6px solid #E48423" }}>
        <img style={{ marginRight: "10px" }} src={square} alt="close" />
        <img
          style={{ marginRight: "10px", marginLeft: "10px" }}
          src={minus}
          alt="minus"
        />
        <img style={{ marginLeft: "10px" }} src={plus} alt="maximize" />
        <h1
          style={{
            display: "inline-block",
            width: "calc(100% - 124px)",
            marginLeft: "10px",
            textAlign: "center",
            padding: "0",
          }}
        >
          {title ?? ""}
        </h1>
      </div>
      <div style={{ padding: "10px" }}>{children}</div>
    </div>
  );
}

export default Window;
